body{
    margin: 0;
}
.MuiButton-root{
    text-transform: capitalize !important;
}
.MuiAlert-message{
    font-size: 12px !important;
}
.opt-input .MuiFormControl-root{
    width: 35px !important;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #bbb;
}
.red-text{
 color: #B72136;
}
.green-text{
    color: #01da8a;
}
.MuiFormLabel-root{
    color: rgb(145, 158, 171) !important;
}
.MuiOutlinedInput-notchedOutline{
    border-color: rgba(145, 158, 171, 0.32) !important
}
.search-bar .MuiOutlinedInput-notchedOutline{
    border-color: rgba(145, 158, 171, 0) !important
}
/* .MuiButtonBase-root{
    border-radius: 8px !important;
} */
.MuiDataGrid-columnHeaders {
    background-color: #f4f6f8 !important;
}

.searchbar.leftcollapsed .MuiDrawer-paper{
    width: calc(100% - 85px) !important;
    left: auto;
    box-shadow: none;
}
.searchbar.leftexpanded .MuiDrawer-paper{
    width: calc(100% - 220px) !important;
    left: auto;
    box-shadow: none;
}
.searchbar .MuiModal-backdrop{
    background-color: rgba(0, 0, 0, 0);

}
.dashboard-filter .MuiDrawer-paper{
    padding: 20px;
}
.country-btn{
    border-color: rgba(145,158,171,.32)!important;
  }

  .notistack-MuiContent{
    color: rgb(33, 43, 54) !important;
    background-color: rgb(255, 255, 255) !important;
    width: 100%;
    padding: 8px;
    margin: 2px 0px;
    box-shadow: rgba(145, 158, 171, 0.16) 0px 8px 16px 0px;
    border-radius: 8px;
  }

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #bbb; 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #aaa; 
}

.img-fluid{
    width: 100%;
}

.bound-sms-icon{
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 20px;
    margin-left: 5px;
    border-radius: 10px;
    display: inline-block;
    font-size: 9px;
    color: #fff;
  }
  .nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }